import React from 'react';
import Eventbooking from '../components/Eventbooking';
import '../styles/Eventbooking.css';

function BookNow() {
  return (
    <div>
      <Eventbooking />
    </div>
  );
}

export default BookNow;
